<template>
  <div>
    <x-select
      class="mb-3"
      v-model="sat_version_cfdi_id"
      dispatchPath="versionCfdiModule/getResource"
      getterListPath="versionCfdiModule/getResourceList"
      :formatListWith="['id', 'version']"
      defaultText="-- Selecciona una versión de CFDI --"
      noClearButton
      prependText="Versión CFDI"
    >
    </x-select>

    <x-select
      class="mb-3"
      v-if="is_nombre_catalogos"
      v-model="sat_nombre_catalogo_id"
      getterListPath="informacionCatalogosModule/getResourceListNombres"
      :formatListWith="['id', 'nombre']"
      defaultText="-- Selecciona un nombre del catálogo --"
      noClearButton
      prependText="Nombre del catálogo"
    >
    </x-select>

    <b-table
      id="catalogoTable"
      v-if="sat_nombre_catalogo_id"
      :fields="fields"
      :items="getResourceList.data"
      striped
      bordered
      responsive
      hover
      stacked="lg"
      show-empty
      small
    >
      <template #cell(atributos)="row">
        <ul>
          <li v-for="(value, key) in JSON.parse(row.item.atributos)" :key="key">
            {{ removeUnderscore(key) }}: {{ value == true ? 'Sí' : 'No' }}
          </li>
        </ul>
      </template>
    </b-table>
    <div v-if="getResourceList.data">
      <paginator
        aria-controls="catalogoTable"
        :disabled="!getAvailableList"
        show-path="informacionCatalogosModule/getResource"
        :resource="getResourceList"
        :more-data="{
          sat_version_cfdi_id: sat_version_cfdi_id,
          sat_nombre_catalogo_id: sat_nombre_catalogo_id
        }"
      ></paginator>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

const STORE_MODULE = 'informacionCatalogosModule'

export default {
  name: 'ReglasList',

  data () {
    return {
      fields: [
        { key: 'clave', label: 'Clave', sortable: true },
        { key: 'nombre', label: 'Nombre', sortable: true },
        { key: 'descripcion', label: 'Descripción', sortable: true },
        { key: 'atributos', label: 'Atributos' },
        { key: 'nombre_catalogo_descripcion', label: 'Descripción del catálogo' }
      ],

      sat_version_cfdi_id: null,

      sat_nombre_catalogo_id: null,

      is_nombre_catalogos: false
    }
  },

  computed: {
    ...mapGetters(STORE_MODULE, [
      'getResourceList',
      'getResource',
      'getAvailableList',
      'getDataNombres',
      'getResourceListNombres'
    ])
  },

  watch: {
    sat_version_cfdi_id: {
      immediate: true,
      handler (value) {
        if (value) {
          this.sat_nombre_catalogo_id = null
          this.is_nombre_catalogos = false
          this.getNombreCatalogos(value)
        }
      }
    },

    sat_nombre_catalogo_id: {
      inmediate: true,
      handler (value) {
        if (value) {
          this.getInformacionCatalogos(value)
        }
      }
    }
  },

  methods: {
    async getNombreCatalogos (sat_version_cfdi_id) {
      const { error, message } = await this.$store.dispatch(`${STORE_MODULE}/getNombres`, { sat_version_cfdi_id })

      if (error) this.$notify({ error, message }, 'Reglas')

      this.is_nombre_catalogos = true
    },

    async getInformacionCatalogos (sat_nombre_catalogo_id) {
      const sat_version_cfdi_id = this.sat_version_cfdi_id
      const { data, error, message } = await this.$store.dispatch(`${STORE_MODULE}/getResource`, { sat_version_cfdi_id, sat_nombre_catalogo_id })

      if (error) this.$notify({ error, message }, 'Reglas')

      console.log(data)
    },

    removeUnderscore (string) {
      return string.replace(/_/g, ' ').replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase() })
    }
  }
}
</script>

<style scoped>

</style>
