<template>
  <base-view title="Información de Catálogos del SAT" icon="check-square">
    <div>
      <check-authorization :requiresAuthorizations="['indice reglas']">
        <div class="card card-header-actions mb-4">
          <b-card-header>
            Información de Catálogos del SAT

            <div class="w-50 ml-auto d-flex justify-content-end align-items-center">
              <!-- Search -->
              <!-- <i-n-p-c-search></i-n-p-c-search> -->
              <!-- Options -->
              <!-- <div class="dropdown no-caret">
                <button
                  class="btn btn-transparent-dark btn-icon dropdown-toggle"
                  type="button"
                  data-toggle="dropdown"
                >
                  <i data-feather="more-vertical"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-right animated--fade-in-up">
                  <a class="dropdown-item my-cursor" @click="getUsers(null)">
                    Recargar
                  </a>
                </div>
              </div> -->
            </div>
          </b-card-header>

          <b-card-body class="p-2">
            <catalogo-list @on-got-item="showForm = true"></catalogo-list>
          </b-card-body>
        </div>
      </check-authorization>
    </div>
  </base-view>
</template>

<script>
import CatalogoList from '@/components/Administracion/SAT/CatalogoList'
import { mapActions, mapGetters } from 'vuex'

const STORE_MODULE = 'reglasModule'

export default {
  name: 'SatInfoCatalogosMainView',

  components: {
    CatalogoList
  },

  data () {
    return {
      hideButton: false,
      showForm: false
    }
  },

  computed: mapGetters(STORE_MODULE, ['isEditingResource']),

  methods: {
    ...mapActions(STORE_MODULE, ['getResources'])
  }
}
</script>

<style scoped>

</style>
